require('./bootstrap.js');

const bg = document.getElementById('bg');
const media = document.getElementById('track-video');
const strap = document.getElementById('strap');
const mediaFrame = document.getElementById('video-holder');
const play = document.getElementById('btn-play');
const back = document.getElementById('btn-back');
const map = document.getElementById('map-holder');
const progress = document.getElementsByClassName('progress-bar')[0];
const form = document.getElementById('entry-form');
const enter = document.getElementById('enter');
const div = document.getElementById('div');
const pick = document.getElementById('pick');

var faded = false;
var car = 0;

function playPauseMedia() {
    strap.style.opacity = "0";
    strap.classList.add('d-none');
    bg.style.opacity = "0";

    if (media.paused) {
        showVideo();

        media.play();
        play.className = play.className.replace('play', 'pause');
    }
    else {
        media.pause();
        play.className = play.className.replace('pause', 'play');
    }
}

function resetMedia() {
    showVideo();
    media.currentTime = 0;
    enter.classList.add('d-none');
    div.classList.add('d-none');
    faded = false;
}

function tick() {
    progress.style.width = parseInt((100 * media.currentTime / media.duration), 10).toString() + '%';

    // let minutes = Math.floor(media.currentTime / 60);
    // let seconds = Math.floor(media.currentTime - minutes * 60);
    // let minuteValue;
    // let secondValue;

    // if (minutes < 10) {
    //     minuteValue = '0' + minutes;
    // } else {
    //     minuteValue = minutes;
    // }

    // if (seconds < 10) {
    //     secondValue = '0' + seconds;
    // } else {
    //     secondValue = seconds;
    // }

    // let mediaTime = minuteValue + ':' + secondValue;
    // document.getElementById('timer').textContent = mediaTime;

    if (!faded && parseInt(media.currentTime) >= 15) {

        faded = true;
        closeVideo();
    }
    else if (parseInt(media.currentTime) >= 15) {
        var ind = parseInt((parseInt(media.currentTime * 1000, 10) - 15000) / 500, 10);
        moveCar(ind);
        // console.log(ind);

    }
}

function showVideo() {
    map.classList.remove('d-flex');
    map.classList.add('d-none');
    mediaFrame.classList.remove('d-none');
    mediaFrame.classList.add('d-flex');

    // This seems to be necessary, otherwise the opacity doesn't do its transition
    if (media.style.opacity == 0) {
        setTimeout(function() {media.style.opacity = 1;}, 100);
    }
}

function closeVideo() {
    media.style.opacity = 0;
}

function fadedVideo(event) {
    if (event.target.style.opacity === "0") {
        showMap();
        // moveCar(0);
    }
}

function moveCar(index) {
    var points = [
        [30, 18], 
        [30, 18], 
        [30, 19], 
        [30, 19], 
        [30, 20], 
        [30, 21], 
        [31, 22], 
        [32, 23], 
        [33, 24], 
        [34, 25], 
        [34, 25], 
        [35, 26], 
        [35, 26], 
        [36, 27], 
        [36, 27], 
        [36, 28], 
        [36, 28], 
        [35, 29], 
        [35, 29], 
        [34, 30], 
        [33, 31], 
        [33, 32], 
        [33, 33], 
        [34, 34], 
        [35, 35]
    ];

    if (index < points.length) {
        f1Map.moveIndicator(points[index][0], points[index][1]);
    }
    else {
        f1Map.hideIndicator();
    }
}

function showMap() {
    mediaFrame.classList.remove('d-flex');
    mediaFrame.classList.add('d-none');
    map.classList.remove('d-none');
    map.classList.add('d-flex');
    enter.classList.remove('d-none');
    div.classList.remove('d-none');

    f1Map.setupGrid();
    
    map.style.opacity = 1;
}

function videoEnded() {
    // Do anything here?
    play.className = play.className.replace('pause', 'play');
    pick.style.animation = 'pick 4s infinite';
}

$( window ).resize(function() {
    f1Map.setupGrid();
});

// self executing function here
(function() {
    if (!window.f1Map) {
        window.f1Map = new F1Map();
    }

    if (play && media) {
        enableInlineVideo(media);

        play.addEventListener('click', playPauseMedia);
        back.addEventListener('click', resetMedia);
        media.addEventListener('timeupdate', tick);
        media.addEventListener('ended', videoEnded);
        media.addEventListener('transitionend', (event) => {
            fadedVideo(event);
        });
    }

    strap.addEventListener('transitionend', (event) => {
        event.target.classList.add('d-none');
    });

    form.addEventListener('submit', submitForm);

    $('#enter').click(function() {
        if (!document.getElementsByClassName('selected').length) {
            $('#modalNothing').modal('show');
        }
        else {
            $('#modalForm').modal('show');
        }
    });

    $('#modalInfo').on('hidden.bs.modal', function (event) {
      console.log('turn off video');
      var infoVid = document.getElementById('info-video');
      if (!infoVid.paused) {
        infoVid.pause();
      }
    });


    setTimeout(function() {$('#modalInfo').modal('show');}, 500);

    // DEBUG: remove this call
    // showVideo();
    // showMap();
    // setTimeout(function() {
    //     f1Map.setupGrid();
    //     var obj = {
    //         target: {
    //             style: {
    //                 opacity: '0'
    //             }
    //         }
    //     };
    //     fadedVideo(obj);
    // }, 750);

    
})();